@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
  @font-face {
    font-family    : $font-family;
    src            : url($file-path + '.eot'); /* IE9 Compat; Modes */
    src            : url($file-path + '.ttf') format('truetype'),
    url($file-path + '.woff2') format('woff2'),
    url($file-path + '.woff') format('woff'),
    url($file-path + '.eot?#iefix') format('embedded-opentype'),
    url($file-path + '.svg') format('svg');
    font-style     : $style;
    font-weight    : $weight;
    text-rendering : optimizeLegibility;
  }
}
